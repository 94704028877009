import { required, maxLength, } from 'vuelidate/lib/validators';
import { onlyText } from '@/_validations/validacionEspeciales';

const nameLength = (value) => {
  let Array = value.split(' ');
  for (let index = 0; index < Array.length; index++) {
    if(Array[index].length > 20){
      return false
    };
  }
  return true;
};

export default () => {
  return {
    rol: {
      StowageRoleName: { required, onlyText, maxLength: maxLength(50), nameLength },
      FgApplyTablet: {required},
      FgSupervisor: {required},
      Status: {required},
    }
  }
}