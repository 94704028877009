<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="showModal"
      class="modal-extended"
    >
      <CRow>
        <CCol sm="11" lg="12">
          <CInput
            v-model="rol.StowageRoleName"
            :value="rol.StowageRoleName"
            v-uppercase
            :horizontal="{ label: 'required text-right col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-7'}"
            :invalid-feedback="errorMessage($v.rol.StowageRoleName)"
            :is-valid="hasError($v.rol.StowageRoleName)"
            @blur="$v.rol.StowageRoleName.$touch()"
            :label="$t('label.name')"
            :placeholder="$t('label.role')+' '+$t('label.name')"
          />
        </CCol>
        <CCol sm="11" lg="12"> 
          <CRow>
            <label class="col-form-label col-sm-12 col-lg-4 text-right ml-2 pt-1">
              {{$t('label.supervisor')}}
            </label>
            <CSwitch
              color="watch"
              variant="3d"
              type="checkbox"
              :checked.sync="rol.FgSupervisor"         
            />
            <label class="col-form-label col-sm-12 col-lg-5 text-right pt-1">
              {{$t('label.applySigetMobile')}}
            </label>
            <CSwitch
              color="watch"
              variant="3d"
              type="checkbox"
              :checked.sync="rol.FgApplyTablet"         
            />
          </CRow>     
        </CCol>         
        <CCol sm="11" lg="12" v-if="actualizar" class="mt-2">
          <CSelect
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-7'}"
            addLabelClasses="text-right required"
            v-model="Status"
            :value.sync="Status"
            :is-valid="statusSelectColor"
            :options="selectOptions"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton outline color="add" @click="changeStatus()" >
          <CIcon name="checkAlt"/>&nbsp;
          {{$t('button.accept')}}
        </CButton>
        <CButton outline color="wipe" @click="showModal=false">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import UpperCase  from '@/_validations/uppercase-directive';
import SingleRoleFormValidation from '@/_validations/estiba/SingleRoleFormValidation';
import General from '@/_mixins/general';


//Data
function data() {
  return {
    isSubmit: false,
    showModal: false,
    Loading: false,
    Status: 1,
    rol: {
      StowageRoleId: '',
      StowageRoleName: '',
      FgApplyTablet: false,
      FgSupervisor: false,
      Status: 1
    },
    iconoCollapse: 'cil-chevron-top-alt',
    textoCollapse: this.$t('label.name'),
    tablaCollapse: false,
    items: [],
    actualizar: false,
    stowageRoleName: '',
    tituloModal: '',
    selectOptionsFgApplyTablet: [
      { 
          value: 0, 
          label: 'NO'
      },
      { 
          value: 1, 
          label: this.$t('label.yes')
      }
    ],
  }
}

//Method
function submit() {
    try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.Loading = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    this.rol.Status = this.Status;
    let metodo = this.actualizar ? 'PUT':'POST';
    let ruta = this.actualizar ? 'StowageRole-update' : 'StowageRole-insert'; 
    let StowageRoleJson = this.actualizar
      ? {
          StowageRoleId: this.rol.StowageRoleId,
          StowageRoleName: this.rol.StowageRoleName,
          FgApplyTablet: this.rol.FgApplyTablet ? 1:0,
          FgSupervisor: this.rol.FgSupervisor ? 1:0,
          Status: this.rol.Status
        }
      : { 
          StowageRoleName: this.rol.StowageRoleName,
          FgApplyTablet: this.rol.FgApplyTablet ? 1:0,
          FgSupervisor: this.rol.FgSupervisor ? 1:0,
        };

    this.$http.ejecutar(metodo, ruta, StowageRoleJson, { root: 'StowageRoleJson' })
    .then(response => {
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });
     this.refreshComponent();
     this.$emit('child-refresh', true);
     this.showModal = false;
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.Loading = false;
    });
  } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}

function refreshComponent() {
  this.rol.StowageRoleId = '';
  this.rol.StowageRoleName = '';
  this.rol.Status = 1;
  this.Status = 1;
  this.rol.FgApplyTablet = false;
  this.rol.FgSupervisor = false;
  this.stowageRoleName= '',
  this.actualizar= false,
  this.$nextTick(() => { this.$v.$reset() })
}

function changeStatus() {
  if (this.Status != this.rol.Status) {
    this.$swal.fire(this.alertProperties({
      text: `${this.$t('label.changeStatusQuestion')} ${this.rol.StowageRoleName}?`,
      })).then((result) => {
      if (result.isConfirmed) {
        this.submit();
      }
    });  
  } else this.submit();
}

function closeModal() {
  this.refreshComponent();
  this.$emit('child-refresh', true);
  this.showModal=false;
}

//Computed
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}

function selectOptions(){
  return [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ];
}

export default {
  name:  'role-modal',
  props: {
    modal: null
  },
  data,
  mixins: [General],
  validations: SingleRoleFormValidation,
  directives: UpperCase,
  methods: {
    submit,
    changeStatus,
    refreshComponent,
    closeModal,
  },
  computed: {
    isSubmitValid,
    selectOptions
  },
  watch: {
    modal: async function () {
      if (this.modal) {
        this.showModal = true;
        if (this.modal==true) {
          this.refreshComponent();
          this.tituloModal = this.$t('label.nuevo')+this.$t('label.role');
        } else {
          this.actualizar = true;
          this.tituloModal = this.$t('label.edit')+' '+this.$t('label.stowageRole')+': '+this.modal.StowageRoleName;
          this.rol.StowageRoleId   = this.modal.StowageRoleId;
          this.rol.StowageRoleName = this.modal.StowageRoleName;
          this.rol.FgApplyTablet = this.modal.FgApplyTablet == true ? true : false;
          this.rol.FgSupervisor = this.modal.FgSupervisor;
          this.rol.Status = this.modal.FgActStowageRole ? 1:0;
          this.Status = this.modal.FgActStowageRole ? 1:0;
          this.stowageRoleName = this.modal.StowageRoleName;
          
          this.$v.$touch();
        }
        
        this.$emit('closeModal');
      }
     
    },

  }
}
</script>
<style lang="scss">
.alto {
  height: 200px;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
</style>

